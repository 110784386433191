














































































































































































































































































import { Mixin_list } from "@/mixin";
// import { api_city, city } from "@/http/city";
import { api_order, orderDetail, orderDetailItem, putItem } from "@/http/order";
import { api_settings } from "@/http/settings";
import { Component } from "vue-property-decorator";
import Page from "@/components/page/index.vue";
import Table from "@/components/table/index.vue";
import axios from "axios";
import JsBarcode from "jsbarcode";
import StorageDb from "@/util/storage";
@Component({
  components: {
    Page,
    Table,
  },
})
export default class extends Mixin_list<orderDetail>(api_order.get_list) {
  username = StorageDb.getLocal("username");
  orderData = {
    id: 0 as number | string,
    phone: "",
    number: "",
    barcode: "",
    order_no: "",
    clear_code: "",
  };
  imageUrls: any[] = [];
  imageUrls2: any[] = [];
  activeIndex = 1;
  tabList = [
    {
      name: "正在出库",
      status: 7,
      index: 1,
    },
    {
      name: "全部出库",
      status: 7,
      index: 2,
    },
  ];
  columns = [
    {
      label: "商品信息",
      slot: "info",
      prop: "info",
      // width: 300,
    },
    {
      label: "用户信息",
      prop: "",
      slot: "userInfo",
      // width: 300,
    },
    {
      label: "订单来源",
      prop: "",
      slot: "orderSource",
      width: 200,
    },
    {
      label: "出库人",
      prop: "",
      slot: "depositor",
      width: 200,
    },
    {
      label: "出库照片",
      prop: "",
      slot: "phone",
      width: 200,
    },
    {
      label: "清洗编号",
      prop: "",
      slot: "number",
    },
  ];
  // 订单类型
  orderType(data: any) {
    if (data == null) return "抖音";
    switch (data.user_type) {
      case 1:
        return "微信";
      case 2:
        return "抖音";
      default:
        return "未知";
    }
  }
  getImageUrl(cover: string) {
    if (cover && typeof cover === "string") {
      // 将字符串按逗号分割成数组
      const coverIds = cover.split(",");
      // 取数组的第一个元素作为图片ID
      const coverId = coverIds[0].trim();
      // 拼接图片URL
      return `https://images.xiyuebiaoju.com/${coverId}`;
    }
  }
  getMiddleIndex(str: String) {
    return Math.floor(str.length / 2);
  }
  getFirstHalf(str: String) {
    const middle = this.getMiddleIndex(str);
    return str.slice(0, middle);
  }
  getSecondHalf(str: String) {
    const middle = this.getMiddleIndex(str);
    return str.slice(middle);
  }
  handleSelect(key: any, keyPath: any) {
    if (key == 1) {
      (this.filter.update_time_after = this.today()),
        (this.filter.update_time_before = this.today());
    } else {
      (this.filter.update_time_after = ""),
        (this.filter.update_time_before = "");
    }
    this.get_list();
  }
  status(status: number) {
    switch (status) {
      case 0:
        return "待支付"; //#在柜子上(1，待取走)完成下单，还为取走的订单。
      case 1:
        return "待取走"; //# 已经支付完成的，还未入库的订单
      case 2:
        return "待入库"; //#已经入库，等待配送，送到清洗工厂的订单
      case 3:
        return "待进场"; //# 已经进入工正在清洗，等待质检的订单
      case 4:
        return "清洗中";
      case 5:
        return "已质检";
      case 6:
        return "待出库"; //# 已经质检等待配送，送回到门店的订单
      case 7:
        return "待到店";
      case 8:
        return "待派送";
      case 9:
        return "完成";
    }
  }
  address(data: any) {
    return `${data.province}${data.city}${data.region}${data.address}`;
  }
  cityList: any[] = [];

  click_cell(row: orderDetail) {
    // return this.$router.push(`/order/edit/?id=${row.id}`);
  }
  // 获取今天的日期（年-月-日格式）
  today = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // 月份从0开始，需要加1并补零
    const day = String(today.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };
  // 获取年月日时分秒
  getDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };
  filter = {
    phone: "",
    order_no: "",
    status: "7",
    update_time_after: this.today(),
    update_time_before: this.today(),
  };
  resetFilter = {
    ...this.filter,
  };

  // 修改takePhoto方法以处理拍照逻辑
  async takePhoto() {
    const videoElement = this.$refs.video as HTMLVideoElement;
    if (!videoElement || !videoElement.srcObject) {
      console.error("未能获取到视频流");
      return;
    }
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d")!;
    canvas.width = videoElement.videoWidth;
    canvas.height = videoElement.videoHeight;
    ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

    const imageData = canvas.toDataURL("image/jpeg");
    // 转换并上传图片
    await this.convertAndUploadImage(imageData);
  }
  // 确保convertAndUploadImage方法内对this.$http有正确的引用
  async convertAndUploadImage(imageData: string) {
    let that = this;
    const blob = this.dataURItoBlob(imageData);
    const file = new File([blob], "photo.jpg", { type: "image/jpeg" });
    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await axios.post(
        "https://api.xiyuebiaoju.com/file_upload/",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      let { data, code } = response.data;
      if (code === 200) {
        that.imageUrls.push(data);
        const url = `https://images.xiyuebiaoju.com/${data}`;
        that.imageUrls2.push(url);
        console.log("上传成功", that.imageUrls, that.imageUrls2);
      }
    } catch (error) {
      // 处理上传失败回调
      console.error("上传失败:", error);
    }
  }
  dataURItoBlob(dataURI: any) {
    const splitIndex = dataURI.indexOf(",");
    const mimeString = dataURI
      .substring(0, splitIndex)
      .split(":")[1]
      .split(";")[0];
    const byteString = atob(dataURI.split(",")[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }

    return new Blob([int8Array], { type: mimeString });
  }
  async mounted() {
    // 1、扫码枪扫码获取订单编号
    await this.addScanMonitor();
    // await this.getCameraPermission();
    // 3、提交拍照照片(入库8张 出库两张)
  }
  nextCode: number | null = null;
  nextTime: number = 0;
  lastCode: any | string = "";
  lastTime: any | string = "";
  code: string = "";
  dialogVisible = false;
  orderNo: string = "";
  // 监听键盘输入
  addScanMonitor() {
    window.document.onkeypress = async (e) => {
      if (window.event) {
        // IE
        this.nextCode = e.keyCode;
      } else if (e.which) {
        // Netscape/Firefox/Opera
        this.nextCode = e.which;
      }

      if (e.which === 13) {
        // 键盘回车事件
        if (this.orderNo.length < 3) return; // 扫码枪的速度很快，手动输入的时间不会让code的长度大于2，所以这里不会对扫码枪有效
        console.log("扫码结束,条形码：", this.orderNo);
        // this.scannedData = this.code;
        // this.scanningForm.scanCode = this.code;
        this.lastCode = "";
        this.lastTime = "";
        // 调用订单检查
        await this.isOrderExists();
        return;
      }

      this.nextTime = new Date().getTime();
      if (!this.lastTime && !this.lastCode) {
        this.orderNo = ""; // 清空上次的条形码
        // 继续扫描一下条前关闭弹窗
        // this.handleCloseTipsVisible();
        this.orderNo += e.key;
        console.log("扫码开始---", this.orderNo);
      }
      if (
        this.lastCode &&
        this.lastTime &&
        this.nextTime - this.lastTime > 500
      ) {
        // 当扫码前有keypress事件时,防止首字缺失
        this.orderNo = e.key;
        console.log("防止首字缺失。。。", this.orderNo);
      } else if (this.lastCode && this.lastTime) {
        this.orderNo += e.key;
        console.log("扫码中。。。", this.orderNo);
      }
      this.lastCode = this.nextCode;
      this.lastTime = this.nextTime;
    };
  }
  // 检测订单是否存在
  async isOrderExists() {
    const info = {
      order_no: String(this.orderNo),
      clear_type: "2",
    };
    await api_order
      .get_item(info)
      // .get_item(6951592317011)
      .then((res) => {
        this.orderData = res;
        console.log("订单数据", this.orderData);
        // this.orderId = results[0].id;
        // // 检测摄像头权限
        // this.getCameraPermission();
        // 订单存在，则提示已出库
        this.handlePut()
      })
      .catch((err) => {
        this.$message.error(err.msg);
      });
  }

  // 获取摄像头权限
  async getCameraPermission() {
    this.dialogVisible = true;
    await this.$nextTick(async () => {
      try {
        const videoElement = this.$refs.video as unknown as
          | HTMLVideoElement
          | undefined;
        if (videoElement) {
          videoElement.srcObject = await this.getWebcamStream();
          console.log("摄像头权限获取成功");
        } else {
          console.error("Video element not found in refs");
        }
      } catch (error: any) {
        if (error.name === "NotAllowedError") {
          this.$message.error(
            "您拒绝了摄像头访问权限，请在浏览器设置中允许访问摄像头。"
          );
        } else if (
          error.name === "NotFoundError" ||
          error.name === "NotReadableError"
        ) {
          this.$message.error("未检测到可用的摄像头设备。");
        } else {
          this.$message.error("未知错误：" + error.message);
        }
      }
    });
  }
  reset() {
    this.filter = { ...this.filter, ...this.resetFilter };
    // (this.$refs.datePicker as Vue & { clearDates: () => void }).clearDates();
    this.find();
  }

  handleClose(done: any) {
    this.$confirm("确认关闭？")
      .then((_) => {
        this.imageUrls = [];
        this.imageUrls2 = [];
        done();
      })
      .catch((_) => {});
  }
  // 获取摄像头权限
  $refs!: {
    video: HTMLVideoElement | undefined;
    notify: HTMLAudioElement | undefined;
  };
  async getWebcamStream() {
    const stream = await navigator.mediaDevices.getUserMedia({
      video: true,
      audio: false,
    });
    return stream;
  }
  // 处理入库
  async handlePut() {
    // JsBarcode("#barcode", this.orderData.order_no);
    const putInfo = {
      id: this.orderData.id,
      status: 7,
      // step3: this.imageUrls.join(),
      get_time: this.getDateTime(),
      clear_code: this.orderData.clear_code,
      get_user: this.username,
    };
    await api_order
      .out_in(putInfo)
      .then((res) => {
        this.dialogVisible = false;
        this.get_list();
        // 提示声音
        this.playAlarm()
        // 一秒后关闭
        setTimeout(()=>{
          this.playAlarmStop()
        },1000)
      })
      .catch((err) => {
        this.$message.error("出库失败");
      });
    // 清除图片
    this.imageUrls = [];
    this.imageUrls2 = [];
  }
  // 获取配置
  config = {
    put_num: 2,
    get_num: 2,
    id: "",
    create_time: "",
    update_time: "",
  };
  get_config() {
    api_settings.get_config().then((res: any) => {
      this.config = res.results[0];
    });
  }
  playAlarm() {
    const audioElement = this.$refs.notify as HTMLAudioElement | undefined;
    if (audioElement) {
      audioElement.play();
    }
  }
  //音乐 关闭
  playAlarmStop() {
    const audioElement = this.$refs.notify as HTMLAudioElement | undefined;
    if (audioElement) {
      audioElement.pause();
    }
  }
  created() {
    this.get_config();
  }
  
}
